
import WidgetDefinition from '@/models/Charts/widgetDefinition';
import { Component, Prop, Vue } from 'vue-property-decorator';
import Chart from '../Charts/Chart.vue';
import { ChartGenerator } from '@/models/Charts/abstract/chartGenerator';
import {
  BendStatesOverviewData,
  BendStateOverview,
  BEND_FLOW_SPEED,
  BEND_PRODUCTION_MODES,
  BendSpeedFlowStates,
} from '@/models/Charts/chartsData';
import i18n from '@/i18n';
import { formatDuration } from '@/utils/dates';
import { FilterTimeSpanEnum } from '@/models/enums/FilterTimeSpanEnum';
import { formatPercentage } from '@/utils/number';
import { getBendStateColor, getBendFlowSpeedColor } from '@/utils/color';
import { isEmpty } from '@/utils/misc';

@Component({
  methods: {
    formatDuration,
    getBendStateColor,
    getBendFlowSpeedColor,
    isEmpty,
  },
  computed: {
    BendStateOverview: () => BendStateOverview,
  },
  components: {
    Chart,
  },
})
export default class BendStatesOverview extends Vue {
  @Prop({ required: true })
  private data!: BendStatesOverviewData | null;

  @Prop({ required: true })
  private widgetDefinition!: WidgetDefinition;

  @Prop({ required: true, default: false, type: Boolean })
  private loading!: boolean;

  /**
   * Chart generator to generate the heatmap charts.
   *
   * Not used to fetch the data. It comes from `data` prop instead.
   */
  @Prop({ required: true })
  private dataRetriever!: ChartGenerator<BendStatesOverviewData>;

  private get productionModeData() {
    return BEND_PRODUCTION_MODES.map((state) => ({
      state,
      current_period: this.data!.current_period.productionMode.find((x) => x.state === state)!
        .seconds,
      previous_period: this.data!.previous_period.productionMode.find((x) => x.state === state)!
        .seconds,
    }));
  }

  private get flowSpeedData() {
    return BEND_FLOW_SPEED.filter((f) => f !== BendSpeedFlowStates.Processing).map((state) => ({
      state,
      current_period: this.data!.current_period.flowSpeed.find((x) => x.state === state)!.seconds,
      previous_period: this.data!.previous_period.flowSpeed.find((x) => x.state === state)!.seconds,
    }));
  }

  private stateValueInPercentOverTime(state: number, total: number): string {
    if (total === 0) {
      return '';
    }
    return formatPercentage((state * 100) / total, 1);
  }

  private get currentPeriodColumnHeader(): string {
    return this.getPeriodColumnHeader('current');
  }

  private get previousPeriodColumnHeader(): string {
    return this.getPeriodColumnHeader('previous');
  }

  private get timeFilterSpan() {
    return this.widgetDefinition.timeFilter as FilterTimeSpanEnum;
  }

  private getPeriodColumnHeader(periodPrefix: 'current' | 'previous'): string {
    const periodKey: string = FilterTimeSpanEnum[this.timeFilterSpan];
    return i18n.t(`${periodPrefix}_time_period.${periodKey}`).toString();
  }
}
